import React from "react";

function Extra(){
    return <>
        <div className="section p-4 myBlock">
            <h2>Extra Carricular</h2>
            <ul>
                <li><b>6 Star - Gold</b> in Problem Solving on Hacker-Rank @lalitkaim</li>
                <li>Code Gladiator <b>Rank 2261</b> out of 416407 participants </li>
                <li>Teaching Assistant for the course, CS112 : <b>Algorithms and Data Structure Lab</b></li>
                <li><b>4<sup>th</sup> Rank</b> (out of 600 candidates) in <b>GK and Aptitude</b> competition (Mar’2015)</li>
            </ul>
        </div>
    </>
}

export default Extra
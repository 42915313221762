import React from "react";

function Copyright(){
    return <>
        <div className="text-center">
            <p style={{margin:"0px", fontSize:"14px"}}>For any query reach out @ <label style={{color:"#e31b6d"}}>kaim.lalit1234@gmail.com</label></p>
            <p style={{margin:"0px", fontSize:"14px"}}>© 2022-Present Lalit, All rights reserved</p>
            <br/>
        </div>
    </>
}

export default Copyright
import React from "react";

function Blog(){
    return<>
        <div className="section myBlock p-4" id="blog">
            <h2>Blog</h2>
            <p><i class="fa-brands fa-blogger"></i> <a href="https://lalitkaim.blogspot.com" style={{textDecoration:"none"}}><label style={{color:"#e31b6d", cursor:"pointer"}}>Blogger</label></a></p>
        </div>
    </>
}

export default Blog